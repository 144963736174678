// Custom.scss
// https://getbootstrap.com/docs/5.3/customize/sass/#importing

// Include custom variables
@import './components/variables';

// Include any default BS variable overrides before import
// Color system
$blue:    #0674db;
$body-color: $textBody;

// Components
$border-radius: 3px;
$border-radius-lg: 3px;
$border-color:  $borderGray600;

// Accordion
$accordion-color: $textBody;
$accordion-bg: $bgWhite;
$accordion-button-active-bg: $bgWhite; //works
$accordion-padding-y: 5px;
$accordion-body-padding-y: 1rem;
$accordion-icon-width: 16px;
$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8' fill='#{$bgGray600}'> <path d='M1.7925 0.60376L6 4.80209L10.2075 0.60376L11.5 1.89626L6 7.39626L0.5 1.89626L1.7925 0.60376Z' /></svg>");
$accordion-button-active-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8' fill='#{$bgGray600}'> <path d='M1.7925 0.60376L6 4.80209L10.2075 0.60376L11.5 1.89626L6 7.39626L0.5 1.89626L1.7925 0.60376Z' /></svg>");

// Forms
$form-check-input-width: 18px;
$form-check-padding-start: 22px;
$form-check-margin-bottom: 6px;
$form-check-input-border: 2px solid $borderGray700;
$form-check-input-hover-border-color: $borderGray800;
$form-check-input-bg-color: $bgWhite;
$form-check-input-hover-bg-color: $bgWhite;
$form-check-input-disabled-bg-color: $bgGray100;
$form-check-input-checked-bg-color: $bgBlue600;
$form-check-input-checked-border-color: $bgBlue600;

// Tables
$table-hover-bg: $bgGray50;
$table-hover-bg-factor: 1;
$table-active-bg: $bgBlue50;
$table-active-bg-factor: 1;
$table-border-color: $borderGray500;

// Validation
$form-feedback-invalid-color:       $bgRed500;
$form-feedback-invalid-text-color:       $textDanger;

// Close
$btn-close-color: $bgGray800;
$btn-close-focus-shadow: none;
$btn-close-width: 12px;

// Modals
$modal-backdrop-bg: $bgBlue950;

// Typography
$hr-opacity: 1;

// Import bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// Then load custom code 
