@import "./variables";

.toast-container > .toast {
  color: #fff !important;
  padding: 15px 15px 15px 35px;
  line-height: 0px;
}
.toast-container > .toast:before {
  position: fixed;
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 18px;
  float: left;
  padding-right: 0.5em;
  margin: auto 0.8em auto -0.8em;
}

.toast-container > .toast-success:before {
  content: "\e86c";
}
.toast-container > .toast-error:before {
  content: "\e002";
}
.toast-container > .toast-info:before {
  content: "\e88e";
}

.toast-success {
  background-color: $bgGreen500;
}
.toast-error {
  background-color: $bgRed600;
}
.toast-info {
  background-color: $bgTeal500;
}
